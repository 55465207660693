import { Autocomplete, Button, DialogActions, DialogContent, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { ExamAccessType } from '../../../../../common/enum/ExamAccessType.enum';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../common/views/examlist/store/ExamsActions'
import { accessTypes } from './data';


const AddUserAccess = ({ closeModal, confirmAction, userAccess, user }: any) => {

    const dispatch = useDispatch()

    const ExamAccessSchema = Yup.object().shape({
        type: Yup.string().required('Type  is required'),
        exams: Yup.array().of(
            Yup.object().shape({
                exam: Yup.string().required('Exam is required'), // these constraints take precedence
                total_times: Yup.number().required('Total Times is required'), // these constraints take precedence
            })
        ),
    });

    const examsList = useSelector((state: any) => {
        return state.exams.exams;
    });

    useEffect(() => {
        user._id && loadExams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const loadExams = async () => {
        await dispatch(actions.getExams());
    }

    const formik = useFormik({
        initialValues: {
            type: userAccess?.type || '',
            startTime: userAccess?.startTime || '',
            endTime: userAccess?.endTime || '',
            total_times: userAccess?.total_times || '',
            completed_times: userAccess?.completed_times || '',
            exams: userAccess?.exams?.map((exam: any) => {
                return {
                    exam: exam?.exam?._id || '',
                    title: exam?.exam?.title || '',
                    total_times: exam.total_times,
                    new: false
                }
            }) || []
        },
        validationSchema: ExamAccessSchema,
        onSubmit: (data) => {
            confirmAction(data);
            closeModal();

        },
    });

    const changeClient = (value: any, index: number) => {
        values.exams[index] = {
            ...values.exams[index],
            exam: value?._id,
        };
        console.log(values);
        setValues({
            ...values,
        });
    };

    const addExamForSelectedExamType = () => {
        if (values.exams.length > 0) {
            values.exams[values.exams.length] = {
                exam: '',
                total_times: '',
                new: true
            };
        } else {
            values.exams[0] = {
                exam: '',
                total_times: '',
                new: true
            };
        }
        setValues({ ...values })
    }

    const {
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        values,
        setValues,
    } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Stack direction='row' spacing={2}>
                                <TextField
                                    fullWidth
                                    select
                                    label='Type'
                                    {...getFieldProps('type')}
                                    error={Boolean(touched.type && errors.type)}
                                    helperText={touched.type && errors.type}
                                >
                                    {accessTypes.map((accessType) =>
                                        <MenuItem key={accessType.value} value={accessType.value}>
                                            {accessType.label}
                                        </MenuItem>)}

                                </TextField>
                                {values.type === ExamAccessType.SELECTED_EXAM && <Button variant="contained" onClick={addExamForSelectedExamType}>Add Exam</Button>}
                            </Stack>
                            {values.type === ExamAccessType.ALL_X_TIMES &&
                                <TextField
                                    fullWidth
                                    type='number'
                                    label='Total Times'
                                    {...getFieldProps(`total_times`)}
                                    error={Boolean(touched.total_times && errors.total_times)}
                                    helperText={touched.total_times && errors.total_times}
                                />
                            }
                            {values.type === ExamAccessType.TIME_FRAME &&
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        type='date'
                                        label='From Date'
                                        {...getFieldProps(`startTime`)}
                                        error={Boolean(touched.startTime && errors.startTime)}
                                        helperText={touched.startTime && errors.startTime}
                                    />
                                    <TextField
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        type='date'
                                        label='To Date'
                                        {...getFieldProps(`endTime`)}
                                        error={Boolean(touched.endTime && errors.endTime)}
                                        helperText={touched.endTime && errors.endTime}
                                    />

                                </Stack>
                            }


                            <FieldArray
                                name='exams'
                                render={(arrayHelpers) =>
                                    values.type === ExamAccessType.SELECTED_EXAM &&
                                    values.exams &&
                                    values.exams.length > 0 &&
                                    values.exams.map((exam: any, index: number) => (
                                        <Grid container spacing={1}>
                                            <Grid item xs={5}>
                                                {exam.new ? <Stack direction="column">
                                                    <Autocomplete
                                                        fullWidth
                                                        options={examsList}
                                                        onChange={(e, newValue) => changeClient(newValue, index)}
                                                        autoHighlight
                                                        getOptionLabel={(option: any) =>
                                                            `${option.title}`
                                                        }
                                                        renderOption={(props, option) => (
                                                            <Box component='li' sx={{ width: 250 }} {...props}>
                                                                {option.title}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Choose a Exam'
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        Boolean(
                                                            getIn(errors, `exams[${index}].exam`)
                                                        ) && <Typography color={'red'} fontSize={10}>{
                                                            getIn(errors, `exams[${index}].exam`)
                                                        }</Typography>}

                                                </Stack> :
                                                    <TextField
                                                        fullWidth
                                                        type='text'
                                                        label='Exam'
                                                        value={exam.title}
                                                        disabled={true}
                                                    />}

                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    label='Total Times'
                                                    {...getFieldProps(`exams[${index}].total_times`)}
                                                    error={Boolean(
                                                        getIn(touched, `exams[${index}].total_times`) &&
                                                        getIn(errors, `exams[${index}].total_times`)
                                                    )}
                                                    helperText={
                                                        getIn(touched, `exams[${index}].total_times`) &&
                                                        getIn(errors, `exams[${index}].total_times`)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant='outlined'
                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                >
                                                    X
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            />


                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => closeModal()}>
                            Cancel
                        </Button>
                        <LoadingButton
                            size='large'
                            type='submit'
                            variant='contained'
                            loading={isSubmitting}
                        >
                            Add Exam Access
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </>
    )
}

export default AddUserAccess
