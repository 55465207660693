import { createTheme } from '@mui/material/styles'
import React from 'react';
import './App.css';
import MainLayout from './hoc/MainLayout';
import ThemeConfig from './theme';

const theme = createTheme();



function App() {
  return (
    <>
      <ThemeConfig theme={theme}><MainLayout /></ThemeConfig>

    </>
  );
}

export default App;
