import palette from './palette';
import { useMemo } from 'react';

// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';


export default function ThemeConfig({ children }: any) {
    const themeOptions = useMemo(
        () => ({
            palette,
        }),
        []
    );

    const theme = createTheme(themeOptions);
    // theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}