
const PRIMARY = {
  lighter: '#E5D68A',
  light: '#DDD101',
  main: '#EDC126',
  dark: '#F4BE2C',
  darker: '#A77B06',
  contrastText: '#242B2E',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
};

export default palette;
